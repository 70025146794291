import { ChatPluginManifest } from '@rzx/chat-plugin-sdk'
import { availablePlugins } from '@/contants/defaultTools'
import { getPluginList } from '@/api'
import { defineStore } from 'pinia'

export const useToolStore = defineStore('tool', () => {
  const manifests = ref<ChatPluginManifest[]>(availablePlugins)

  const fetchPlugins = async () => {
    try {
      const res = await getPluginList<{ code: 1 | 0; data: { content: string }[] }>()
      const plugins = res.data.map((item) => JSON.parse(item.content)).filter(Boolean)
      manifests.value = plugins as ChatPluginManifest[]
    } catch (error) {
      console.error('获取插件列表失败:', error)
      // 加载失败时使用默认插件
      manifests.value = availablePlugins
    }
  }
  // 初始化时获取插件列表
  onMounted(() => {
    // fetchPlugins()
  })
  // 根据identifier获取插件
  const getToolByIdentifier = (identifier: string) => {
    return manifests.value.find((tool) => tool.identifier === identifier)
  }
  return { manifests, getToolByIdentifier }
})
