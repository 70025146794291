import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { log } from '@/utils/prettyLog'
import Directives from './directive'
import { auth } from './permission/vAuth'
import pkg from '../package.json'
import { createApp } from 'vue'
import router from './router'
import pinia from './store'
import './pollyfill'
import './styles/index.scss'
import './styles/shadcn.css'
import App from './App'

const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(Directives)
app.directive('auth', auth)
app.use(autoAnimatePlugin)
app.mount('#app')
log.success(pkg.version, '欢迎使用博闻智星')
