import { get, post } from '@/utils/request'
/**
 * @description 资源树
 */
export function getResourceTree<T = any>() {
  return get<T>({
    url: '/llm/aios/getResourceTree',
  })
}

// 新增节点
export function saveOrUpdateTree<T = any>(data: any) {
  return post<T>({
    url: '/llm/aios/saveOrUpdateTree',
    data,
  })
}

// 获取树目录下的节点信息
export function getProcessById<T = any>(data: any) {
  return get<T>({
    url: '/llm/aios/getProcessById',
    data,
  })
}

// 保存
export function aiosSave<T = any>(data: any) {
  return post<T>({
    url: '/llm/aios/save',
    data: data,
  })
}

// 执行接口
export function debug<T = any>(data: any) {
  return post<T>({
    url: '/llm/aios/debugCurrent',
    data: data,
  })
}

// 编译接口
export function compiler<T = any>(data: any) {
  return post<T>({
    url: '/llm/aios/compiler',
    data: data,
  })
}

// 删除接口
export function deleteTree<T = any>(data: any) {
  return get<T>({
    url: '/llm/aios/deleteTree',
    data,
  })
}

// 获取数据开发节点信息
export function getNodeInfo<T = any>() {
  return post<T>({
    url: '/llm/aios/getNodeInfo',
  })
}
