import logopng from '@/assets/logo.png'
import { cn } from '@/utils'

import { Skeleton } from '@/components/ui/skeleton'
import Front from '@/pages/front/index.vue'
import { chatStore } from '@/store'
export const DesktopLoading = () => {
  const chatstore = chatStore()
  const fh = () => {
    chatstore.stoptLoadingToWork()
  }
  if (chatstore.workType === 'front') {
    return (
      <div class='h-full w-full'>
        <div class={cn('flex  h-16 border-b')}>
          <div class='ml-3 flex items-center gap-2'>
            <img src={logopng} alt='logo' class='w-9 h-9' />
            <span class='text-base lg:text-xl font-bold tracking-wide'>博闻智星</span>
            <span>
              <n-button quaternary onClick={fh}>
                返回
              </n-button>
            </span>
          </div>
        </div>
        <div class='flex h-full border-r'>
          <Front></Front>
        </div>
      </div>
    )
  }
  return (
    <div class='h-full w-full'>
      {chatstore.isLoadingToWork && (
        <>
          <div class={cn('flex  h-16 border-b')}>
            <div class='ml-3 flex items-center gap-2'>
              <img src={logopng} alt='logo' class='w-9 h-9' />
              <span class='text-base lg:text-xl font-bold tracking-wide'>工作台</span>
            </div>
          </div>
          <div class='flex h-full'>
            <div style='width: 26.8%' class='flex h-full'>
              <div class='h-full flex'>
                <div class='w-[50px] bg-background py-4 flex flex-col border-r'>
                  <div class='flex justify-center w-full py-2 group cursor-pointer transition-all mb-3 hover:text-primary relative before:absolute before:left-0 before:top-0 before:h-full before:w-[3px] text-primary/85 before:bg-primary'>
                    <Skeleton class='h-8 w-8' />
                  </div>
                  <div class='flex justify-center w-full py-2 group cursor-pointer transition-all mb-3 hover:text-primary relative before:absolute before:left-0 before:top-0 before:h-full before:w-[3px]'>
                    <Skeleton class='h-8 w-8' />
                  </div>
                  <div class='flex justify-center w-full py-2 group cursor-pointer transition-all mb-3 hover:text-primary relative before:absolute before:left-0 before:top-0 before:h-full before:w-[3px]'>
                    <Skeleton class='h-8 w-8' />
                  </div>
                </div>
              </div>
              <div class='w-full'>
                <div class='h-full w-full overflow-auto border-r'>
                  <div class='text-sm px-4 py-1.5 bg-background border-b flex items-center justify-between'>
                    <span class=''>
                      <Skeleton class='h-5 w-full' />
                    </span>
                    <div class='flex-1 flex justify-end gap-2'>
                      <Skeleton class='h-5 w-full' />
                    </div>
                  </div>
                  <div class='px-2'>
                    <div class='my-2'>
                      <Skeleton class='h-4 w-full' />
                    </div>
                    <div class='my-2'>
                      <Skeleton class='h-4 w-full' />
                    </div>
                    <div class='my-2'>
                      <Skeleton class='h-4 w-full' />
                    </div>
                    <div class='my-2'>
                      <Skeleton class='h-4 w-full' />
                    </div>
                    <div class='my-2'>
                      <Skeleton class='h-4 w-full' />
                    </div>
                    <div class='my-2'>
                      <Skeleton class='h-4 w-full' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style='width: 73.2%' class='border-r'>
              <div class='text-sm px-4 py-1.5 bg-background border-b flex items-center justify-between'>
                <div>
                  <Skeleton class='h-5 w-[200px]' />
                </div>
                <div class='flex-1 flex justify-end gap-2'>
                  <div>
                    <Skeleton class='h-5 w-[80px]' />
                  </div>
                </div>
              </div>
              <div class='h-full flex flex-col justify-between'>
                <div class='px-2 bg-neutral-50' style='height: calc(100% - 306px)'>
                  <div class='my-2'>
                    <Skeleton class='h-4 w-full' />
                  </div>
                  <div class='my-2'>
                    <Skeleton class='h-4 w-full' />
                  </div>
                  <div class='my-2'>
                    <Skeleton class='h-4 w-full' />
                  </div>
                  <div class='my-2'>
                    <Skeleton class='h-4 w-full' />
                  </div>
                  <div class='my-2'>
                    <Skeleton class='h-4 w-full' />
                  </div>
                  <div class='my-2'>
                    <Skeleton class='h-4 w-full' />
                  </div>
                </div>
                <div class='h-[394px]  bg-neutral-50'>
                  <div class='h-full w-full overflow-auto border-t'>
                    <div class='text-sm px-4 py-1.5 bg-background border-b flex items-center justify-between'>
                      <span class=''>
                        <Skeleton class='h-5 w-[200px]' />
                      </span>
                      <div class='flex-1 flex justify-end gap-2'>
                        <Skeleton class='h-5 w-[80px]' />
                      </div>
                    </div>
                    <div class='px-2'>
                      <div class='my-2'>
                        <Skeleton class='h-4 w-full' />
                      </div>
                      <div class='my-2'>
                        <Skeleton class='h-4 w-full' />
                      </div>
                      <div class='my-2'>
                        <Skeleton class='h-4 w-full' />
                      </div>
                      <div class='my-2'>
                        <Skeleton class='h-4 w-full' />
                      </div>
                      <div class='my-2'>
                        <Skeleton class='h-4 w-full' />
                      </div>
                      <div class='my-2'>
                        <Skeleton class='h-4 w-full' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
