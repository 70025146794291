<template>
  <div class="w-full h-full">
    <router-view>
      <template #default="{ Component }">
        <KeepAlive include="files,MobileChat,Chat">
          <Suspense>
            <component :is="Component" :key="route.path" />
            <!-- 加载中状态 -->
            <!-- <template #fallback> 正在加载... </template> -->
          </Suspense>
        </KeepAlive>
      </template>
    </router-view>
  </div>
</template>

<script lang="ts" setup>
import { useResponsive } from '@/hooks/useResponsive'
import { RouterView } from 'vue-router'
import { useRoute } from 'vue-router'

defineOptions({ name: 'ChildView' })
const route = useRoute()
const { isMobile } = useResponsive()
</script>
<style lang="scss">
.child-view {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
}
/* 进入 */
.slide-fade-X-enter-from {
  opacity: 0;
  transform: scale(1);
  transition: all 0.8s cubic-bezier(0.55, 0, 0.1, 1);
  transform: translateX(-50px);
}
.slide-fade-Y-leave-active,
.slide-fade-Y-enter-active {
  transition: all 0.8s cubic-bezier(0.55, 0, 0.1, 1);
}
/* 离开 */
.slide-fade-Y-leave-to {
  opacity: 0;
  transform: scale(0);
  transform: translateY(50px);
}
/* 进入 */
.slide-fade-Y-enter-from {
  opacity: 0;
  transform: scale(1);
  transform: translateY(-50px);
}
/* 离开 */
.slide-fade-X-leave-active,
.slide-fade-X-enter-active {
  transition: all 0.8s cubic-bezier(0.55, 0, 0.1, 1);
}
.slide-fade-X-leave-to {
  opacity: 0;
  transform: scale(0);
  transition: all 0.8s cubic-bezier(0.55, 0, 0.1, 1);
  transform: translateX(50px);
}
/* 进入 */
.slide-fade-X-enter-from {
  opacity: 0;
  transform: scale(1);
  transition: all 0.8s cubic-bezier(0.55, 0, 0.1, 1);
  transform: translateX(-50px);
}
</style>
