<script setup lang="ts">
import { getProcessById } from '@/api/resouce'
import XyFlow from '@/components/XyFlow/index.vue'
import { developStore } from '@/store'
const developstore = developStore()
defineOptions({ name: 'Flow' })
const dashboardRef = inject<any>('dashboard')
const { taskId, flowData } = dashboardRef
const nodes = ref<any[]>([])
const edges = ref<any[]>([])
const position = { x: 0, y: 0 }
const nodeType = 'process'
const transformStepsToFlowData = (steps: any[]) => {
  const nodes: any[] = []
  const edges: any[] = []

  steps.forEach((item, index) => {
    // 处理节点
    nodes.push({
      id: item.id.toString(),
      position,
      type: nodeType,
      data: { label: `${index + 1}. ${item.stepDesc}`, tools: item.tools },
    })

    // 处理边（如果不是最后一个节点）
    if (steps[index + 1]) {
      edges.push({
        id: index.toString(),
        source: item.id.toString(),
        target: steps[index + 1].id.toString(),
        type: 'animation',
      })
    }
  })

  return { nodes, edges }
}
onMounted(async () => {
  const res = await getProcessById({ id: taskId.value })
  if (res.code === 1) {
    const { nodes: nodesData, edges: edgesData } = transformStepsToFlowData(res.data.stepInfo)
    nodes.value = nodesData
    edges.value = edgesData
    // console.log(nodes.value, edges.value, 'nodes, edges')
  }
})

const nodes2 = ref<any[]>([])
const edges2 = ref<any[]>([])
const transformStepsToFlowData2 = (nodeData: any[]) => {
  const nodes2: any[] = []
  const edges2: any[] = []
  if (nodeData && nodeData.nodes && nodeData.nodes.length) {
    nodeData.nodes.forEach((item, index) => {
      const nodedata: any = JSON.parse(item.nodeInfo)
      const dataArrInfo = []
      if (nodedata.properties.dataBase) {
        dataArrInfo.push({
          title: '',
          type: 'key',
          data: [
            {
              key: '库名：',
              value: nodedata.properties.dataBase,
            },
          ],
        })
      }
      if (nodedata.properties._parameter_table) {
        dataArrInfo.push({
          title: '',
          type: 'key',
          data: [
            {
              key: '表名：',
              value: nodedata.properties._parameter_table,
            },
          ],
        })
      }
      if (nodedata.insert_data.properties) {
        dataArrInfo.push({
          title: '',
          type: 'key',
          data: [
            {
              key: '写入模式：',
              value: nodedata.insert_data.properties._parameter_sinkType,
            },
            {
              key: '写入表名：',
              value: nodedata.insert_data.properties._parameter_table,
            },
          ],
        })
      }
      if (nodedata.insert_data.schema) {
        dataArrInfo.push({
          title: '',
          type: 'table',
          data: nodedata.insert_data.schema,
        })
      }
      // 处理节点
      nodes2.push({
        id: item.id.slice(4).toString(),
        position,
        type: nodeType,
        data: {
          label: `${index + 1}. ${nodedata.name}`,
          tools: {
            dataArrInfo: dataArrInfo,
          },
        },
      })

      // 处理边（如果不是最后一个节点）
      if (nodeData.nodes[index + 1]) {
        edges2.push({
          id: index.toString(),
          source: item.id.slice(4).toString(),
          target: nodeData.nodes[index + 1].id.slice(4).toString(),
          type: 'animation',
        })
      }
    })
  }
  // console.log('12321312312312321', nodes2, edges2)
  return { nodes2, edges2 }
}
const setFlow2 = (val) => {
  nodes2.value = []
  edges2.value = []

  const { nodes2: nodesData, edges2: edgesData } = transformStepsToFlowData2(val)
  nodes2.value = nodesData
  edges2.value = edgesData
}
const showNodeInfo = ref(false)
watch(
  () => developstore.nodeInfo.value,
  async (val) => {
    if (val && val.nodes && val.nodes.length) {
      showNodeInfo.value = true
      await nextTick()
      setFlow2(val)
    } else {
      showNodeInfo.value = false
    }
  },
  { deep: true },
)
</script>

<template>
  <div class="w-full h-full">
    <XyFlow
      v-show="!showNodeInfo"
      v-model:nodes="nodes"
      v-model:edges="edges"
      :flow-data="flowData"
    ></XyFlow>
    <XyFlow
      v-show="showNodeInfo"
      v-model:nodes="nodes2"
      v-model:edges="edges2"
      :flow-data="[]"
    ></XyFlow>
  </div>
</template>

<style lang="scss" scoped></style>
