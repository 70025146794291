// import { ChatPluginManifest } from '@rzx/chat-plugin-sdk'
export const presetTools: any[] = [
  {
    $schema: '../node_modules/@rzx/chat-plugin-sdk/schema.json',
    api: [
      {
        name: 'create_api_schema',
        description: '根据描述生成一个API服务的schema结构',
        parameters: {
          type: 'object',
          properties: {
            name: {
              type: 'string',
              description: 'API服务的名称',
            },
            url: {
              type: 'string',
              description: 'API服务的请求地址, 可选',
            },
            description: {
              type: 'string',
              description: 'API服务的详细描述',
            },
            parameters: {
              type: 'object',
              description: 'API的参数定义',
              properties: {
                param_name: {
                  type: 'string',
                  description: '参数名称',
                },
                param_type: {
                  type: 'string',
                  description: '参数类型',
                  enum: ['string', 'number', 'boolean', 'array', 'object'],
                },
                param_description: {
                  type: 'string',
                  description: '参数的描述',
                },
                required: {
                  type: 'boolean',
                  description: '是否是必需参数',
                },
              },
            },
          },
          required: ['name', 'description', 'parameters'],
        },
      },
    ],
    identifier: 'plugin-create-api-schema',
    meta: {
      avatar: '🔧',
      title: '创建API Schema',
      description: '根据描述生成一个API服务的schema结构',
    },
    systemRole: '根据描述生成一个API服务的schema结构',
    type: 'markdown',
    version: '1',
  },
  {
    $schema: '../node_modules/@rzx/chat-plugin-sdk/schema.json',
    api: [
      {
        name: 'new_workflow',
        description: '新建任务或者工作流',
        parameters: {
          type: 'object',
          properties: {
            action: {
              type: 'string',
              description: 'action name default is new-workflow',
              default: 'task',
            },
          },
          required: ['action'],
        },
      },
    ],
    identifier: 'plugin-app-command',
    meta: {
      avatar: '🔧',
      title: '指令集',
      description: 'Operate the page in a conversational manner.',
    },
    systemRole: 'Operate the page in a conversational manner.',
    type: 'action',
    version: '1',
  },
]
