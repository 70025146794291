import { defineStore, storeToRefs } from 'pinia'
import { getDefaultChatFlow } from '@/api'
import { useChatDB, userStore } from '@/store'
import { v4 as uuidv4 } from 'uuid'
import { defaultAgent } from '@/contants/defaultAnget'

export const CHAT_KEY = 'chat-list'

export const chatStore = defineStore(
  'chat-store',
  () => {
    const isAbort = ref(true)
    const chatDB = useChatDB()
    const { userInfo } = storeToRefs(userStore())
    const initalAnget = ref<IAgent>(defaultAgent)
    const angetList = ref<ITopics[]>([]) // 存储的chatList
    const activeAgent = ref<ITopics | undefined>(initAnget) // 当前激活的agent
    const activeAgentId = ref<string>(defaultAgent.id) // 当前激活的agentId
    const activeTopic = ref<ITopic | undefined>(undefined) // 当前技能活动的topic
    /**
     * 初始化聊天列表数据。
     * 该异步函数从数据库中获取聊天主题列表，并对数据进行处理以适配当前的应用需求。
     * 主要处理了旧数据的兼容问题，确保每个话题都有一个唯一的id，并为没有agentId的话题分配了agentId。
     */
    const init = async () => {
      // 从存储取chatList
      const res = await chatDB.get<ITopics[]>(CHAT_KEY)
      if (res) {
        angetList.value = res.map((item) => {
          if (!item.agentId) {
            // 兼容旧数据
            item.userId = ''
            item.agentId = item.id
            item.id = uuidv4()
          }
          return item
        })
      }
    }
    /**
     * 重置activeAgent
     */
    const resetActiveAgent = () => {
      setDefaultAnget(initalAnget.value)
      activeAgentId.value = initalAnget.value.id
      activeTopic.value = activeAgent.value?.children[0]
    }
    /**
     * @description 新增新会话
     */
    const addChat = async (agent: IAgent) => {
      activeAgentId.value = agent.id
      const DBTopics = await chatDB.get<ITopics[]>(CHAT_KEY)
      // 查询用户是否有该会话
      const currentAnget = DBTopics?.find(
        (item) => item.agentId === agent.id && userInfo.value.userId === item.userId,
      )
      // 没有就添加，有就不操作, 同时设置当前激活的agent
      const defaultTopic = setDefaultMessages(agent.tageDesc || agent.description)
      if (!currentAnget) {
        const newTopic: ITopics = {
          ...agent,
          agentId: agent.id,
          id: uuidv4(),
          userId: userInfo.value.userId,
          children: [defaultTopic],
        }
        angetList.value.push(newTopic)
        activeAgent.value = newTopic
      } else {
        const index = DBTopics?.findIndex(
          (item) => item.agentId === agent.id && userInfo.value.userId === item.userId,
        )
        // 更新本地存储的agent(订阅的技能的名称, 描述, 图标可能有变化)
        activeAgent.value = {
          ...currentAnget,
          ...{
            isStream: agent.isStream,
            isFileUpload: agent.isFileUpload,
            isPlugin: agent.isPlugin,
            pluginDetail: agent?.pluginDetail,
            type: agent.type,
            name: agent.name,
            description: agent.description,
            tageDesc: agent?.tageDesc,
            avatar: agent.avatar,
          },
        }
        // 更新新会话信息
        const index1 = activeAgent.value!.children.findIndex((item) => item.id === '')
        if (agent.type !== 'human-chat') {
          activeAgent.value!.children[index1] = defaultTopic
        }
        angetList.value[index!] = activeAgent.value
      }
      const chatList = activeAgent.value?.children || []
      activeTopic.value = chatList.find((item) => item.id === '')

      await chatDB.set(CHAT_KEY, toRaw(angetList.value))
    }
    /**
     * @description 设置默认topic
     * @param msg
     * @returns
     */
    const setDefaultMessages = (msg: string | undefined): ITopic => {
      return {
        id: '',
        topic: '新会话',
        lastUpdate: new Date().toString(),
        extra: {},
        messages: [
          {
            id: '',
            role: 'apiMessage',
            content: msg,
            date: new Date().toString(),
          },
        ],
      }
    }
    const setDefaultAnget = (agent: IAgent) => {
      const topic = setDefaultMessages(agent.tageDesc || agent.description)
      activeAgent.value = {
        ...agent,
        agentId: agent.id,
        id: uuidv4(),
        userId: '',
        children: [topic],
      }
    }
    /**
     * @description 获取默认技能
     */
    const getDefaultAgent = async () => {
      const anget = await fetchDefaultAgent()
      if (anget) {
        setDefaultAnget(anget)
        addChat(anget)
      }
    }
    /**
     *  @description 接口获取默认技能
     */
    const fetchDefaultAgent = async () => {
      try {
        const res = await getDefaultChatFlow()
        if (res.code === 1) {
          const item = res.data
          const anget = {
            id: item.id,
            name: item.name,
            type: item.type,
            description: item.desc,
            tageDesc: item?.tageDesc,
            avatar: item.picturePath,
            isStream: item.isStream,
            isFileUpload: item.isFileUpload,
            isPlugin: item.isPlugin,
            pluginDetail: JSON.stringify(item?.pluginDetail),
          }
          initalAnget.value = anget
          return anget
        }
      } catch (error) {
        console.log(error)
      }
    }
    /**
     * @description 更新聊天
     */
    const updateChat = (chat: ITopics) => {
      const index = angetList.value.findIndex((item) => item.id === chat.id)
      if (index !== -1) {
        angetList.value[index] = chat
      }
    }
    /**
     * @description setActiveAgent
     */
    const setActiveAgent = (agent: IAgent) => {
      activeAgentId.value = agent.id
    }
    /**
     * @description 删除聊天
     * @param id 会话id
     */
    const deleteChat = (id: string) => {
      const index = angetList.value.findIndex((item) => item.id === id)
      index > -1 && angetList.value.splice(index, 1)
      chatDB.set(CHAT_KEY, toRaw(angetList.value))
    }
    /**
     * @description 置顶
     * @param id 会话id
     */
    const pinChat = (id: string) => {
      const index = angetList.value.findIndex((item) => item.id === id)
      if (index !== -1) {
        const chat = angetList.value.splice(index, 1)
        angetList.value.unshift(chat[0])
        chatDB.set(CHAT_KEY, toRaw(angetList.value))
      }
    }
    /**
     * @description 取消置顶
     * @param id 会话id
     */
    const unpinChat = (id: string) => {
      const index = angetList.value.findIndex((item) => item.id === id)
      if (index !== -1) {
        const chat = angetList.value.splice(index, 1)
        angetList.value.push(chat[0])
        chatDB.set(CHAT_KEY, toRaw(angetList.value))
      }
    }

    /**
     * @description 根据id 获取聊天
     * @param id  技能Id
     */
    const getChatByAgentId = async (id: string) => {
      if (userInfo.value.userId) {
        activeAgent.value = angetList.value?.find(
          (item) => item.agentId === id && userInfo.value.userId === item.userId,
        )
      }
    }

    /**
     * @description 根据当前激活的agent 更新angetList
     */
    const updateActiveAgent = () => {
      if (activeAgent.value) {
        // angetList.value = angetList.value.filter((item) => item.userId)
        const index = angetList.value.findIndex((item) => item.id === activeAgent.value?.id)
        if (index !== -1) {
          angetList.value[index] = activeAgent.value
          chatDB.set(CHAT_KEY, toRaw(angetList.value))
        }
      }
    }
    /**
     * @description 根据用户清除所有记录
     */
    const clearAllChat = () => {
      angetList.value = []
      setDefaultAnget(initalAnget.value)
      activeAgentId.value = initalAnget.value.id
      activeTopic.value = activeAgent.value?.children[0]
      return chatDB.set(CHAT_KEY, [])
    }
    /**
     * @description  用户登录后, 设置当前会话
     */
    const setActiveAgentByUserId = () => {
      if (userInfo.value.userId) {
        activeAgent.value = angetList.value?.find((item) => {
          return item.agentId === activeAgentId.value && item.userId === userInfo.value.userId
        })
        // 没有匹配到，说明是新用户，需要给用户创建一个新的默认会话
        if (!activeAgent.value) {
          addChat(initalAnget.value)
        }
      }
    }

    const isLoadingToWork = ref(false) //是否处于加载到工作台中状态
    const workType = ref('')
    const startLoadingToWork = (type) => {
      isLoadingToWork.value = true
      workType.value = type
    }
    const stoptLoadingToWork = () => {
      isLoadingToWork.value = false
      workType.value = ''
    }
    return {
      init,
      isAbort,
      angetList,
      activeAgent,
      activeTopic,
      initalAnget,
      activeAgentId,
      fetchDefaultAgent,
      getDefaultAgent,
      clearAllChat,
      setActiveAgent,
      addChat,
      updateChat,
      deleteChat,
      pinChat,
      unpinChat,
      resetActiveAgent,
      getChatByAgentId,
      updateActiveAgent,
      setActiveAgentByUserId,
      isLoadingToWork,
      startLoadingToWork,
      stoptLoadingToWork,
      workType,
    }
  },
  {
    persist: {
      key: 'chatstore',
      storage: localStorage,
      paths: ['initalAnget'],
    },
  },
)
export const initAnget: ITopics = {
  ...defaultAgent,
  agentId: defaultAgent.id,
  id: uuidv4(),
  userId: '',
  children: [
    {
      id: '',
      topic: '新会话',
      lastUpdate: new Date().toString(),
      extra: {},
      messages: [
        {
          id: '',
          role: 'apiMessage',
          content: defaultAgent.description,
          date: new Date().toString(),
        },
      ],
    },
  ],
}
