import { useResponsive } from '@/hooks/useResponsive'
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable'
import { chatStore } from '@/store'
import './index.scss'
import { DesktopLoading } from './DesktopLoading'

export const Desktop = defineComponent({
  name: 'Desktop',
  setup(_props, { slots }) {
    const router = useRouter()
    const panelRef = ref<InstanceType<typeof ResizablePanel>>()

    const isCollapsed = ref(false)
    function onCollapse() {
      isCollapsed.value = true
    }

    function onExpand() {
      isCollapsed.value = false
    }
    const { breakpoints } = useResponsive()

    watch(
      () => breakpoints.active().value,
      () => {
        nextTick(() => {
          const isDesktop = breakpoints.active().value === 'desktop'
          panelRef.value?.resize(isDesktop ? 17 : 28)
        })
      },
      { immediate: true },
    )
    const loadingRef = ref(null)
    const chatstore = chatStore()
    const openLoading = () => {
      panelRef.value.resize(75)
      nextTick(() => {
        if (chatstore.workType === 'work') {
          setTimeout(() => {
            router.push('/develop')
          }, 120)
        }
      })
    }

    const closeLoading = () => {
      panelRef.value.resize(18)
      // nextTick(() => {
      //   if (chatstore.workType === 'work') {
      //     setTimeout(() => {
      //       router.push('/develop')
      //     }, 120)
      //   }
      // })
    }

    watch(
      () => chatstore.isLoadingToWork,
      (val) => {
        if (val) {
          openLoading()
        } else {
          closeLoading()
        }
      },
    )

    return () => (
      <>
        {/* <button onClick={clickHandle}>{isCollapsed.value ? '展开' : '收起'}</button> */}
        {/* <button onClick={test}>测试</button> */}
        <ResizablePanelGroup
          id='handle-demo-group-1'
          direction='horizontal'
          class='h-full w-full items-stretch'>
          <ResizablePanel
            ref={panelRef}
            id='handle-demo-panel-1'
            onExpand={onExpand}
            onCollapse={onCollapse}
            collapsible
            collapsedSize={0}
            defaultSize={18}
            class={chatstore.isLoadingToWork ? 'hidden' : ''}>
            {/* 插槽 */}
            {slots.left?.()}
          </ResizablePanel>
          <div
            ref={loadingRef}
            class={chatstore.isLoadingToWork ? 'width70 transition-all' : 'transition-all w-0'}>
            <DesktopLoading></DesktopLoading>
          </div>
          {!chatstore.isLoadingToWork && <ResizableHandle id='handle-demo-handle-1' with-handle />}
          <ResizablePanel id='handle-demo-panel-2'>
            {/* 插槽 */}
            {slots.right?.()}
          </ResizablePanel>
        </ResizablePanelGroup>
      </>
    )
  },
})
