<script setup lang="ts">
import { nextTick, ref } from 'vue'
import { MoveHorizontal, MoveVertical, Shuffle, Play, Pause } from 'lucide-vue-next'
import { Panel, VueFlow, useVueFlow } from '@vue-flow/core'
import AnimationEdge from './AnimationEdge.vue'
import { Background } from '@vue-flow/background'
import { useRunProcess } from './useRunProcess'
import { initialEdges, initialNodes } from './initial-elements'
import { useLayout } from './useLayout'
import ProcessNode from './ProcessNode.vue'

import { useResizeObserver } from '@vueuse/core'
const flowContainer = ref<HTMLDivElement>()
useResizeObserver(flowContainer, () => {
  nextTick(() => {
    fitView()
  })
})

defineOptions({
  name: 'XyFlow',
})

const props = defineProps(['flowData'])

const cancelOnError = true
const nodes = defineModel<any[]>('nodes', { required: true })
const edges = defineModel<any[]>('edges', { required: true })
// const nodes = ref()
// const edges = ref()
// setTimeout(() => {
//   nodes.value = initialNodes
//   edges.value = initialEdges
// }, 2000)

const { graph, layout, previousDirection } = useLayout()

// const { run, stop, reset, isRunning } = useRunProcess({ graph, cancelOnError })

const { fitView, updateNodeData } = useVueFlow({
  defaultEdgeOptions: {
    type: 'animation',
  },
})
async function shuffleGraph() {
  // await stop()

  // reset(nodes.value)

  // edges.value = shuffle(nodes.value)

  nextTick(() => {
    layoutGraph(previousDirection.value)
  })
}
async function layoutGraph(direction) {
  nodes.value = layout(nodes.value, edges.value, direction)

  nextTick(() => {
    setTimeout(() => {
      fitView()
    }, 100)
  })
}
watch(
  () => props.flowData,
  (newNodes) => {
    nextTick(() => {
      newNodes.forEach((item) => {
        nodes.value.forEach((node) => {
          if (item.id === node.id) {
            // 只有当节点状态需要更新时才更新
            setTimeout(() => {
              updateNodeData(node.id, {
                isRunning: item.status === 'isRunning',
                isFinished: item.status === 'isFinished',
                hasError: false,
                isSkipped: false,
                isCancelled: false,
              })
            }, 100)
          }
        })
      })
    })
  },
  { deep: true, immediate: true },
)
</script>

<template>
  <div ref="flowContainer" class="h-full w-full">
    <VueFlow
      :nodes="nodes"
      :default-edge-options="{ type: 'animation', animated: true }"
      :edges="edges"
      @nodes-initialized="layoutGraph('LR')"
    >
      <Background :gap="16" />
      <template #node-process="props">
        <ProcessNode
          :data="props.data"
          :source-position="props.sourcePosition"
          :target-position="props.targetPosition"
        />
      </template>

      <template #edge-animation="edgeProps">
        <AnimationEdge
          :id="edgeProps.id"
          :source="edgeProps.source"
          :target="edgeProps.target"
          :source-x="edgeProps.sourceX"
          :source-y="edgeProps.sourceY"
          :targetX="edgeProps.targetX"
          :targetY="edgeProps.targetY"
          :source-position="edgeProps.sourcePosition"
          :target-position="edgeProps.targetPosition"
        />
      </template>

      <Panel class="flex gap-2.5" position="top-right">
        <div class="flex flex-col bg-gray-800 p-2.5 rounded-lg shadow-lg">
          <div class="flex gap-2.5">
            <!-- <Button size="icon" variant="outline" @click="run(nodes)">
              <Play class="size4" />
            </Button> -->

            <Button size="icon" variant="outline" @click="layoutGraph('LR')">
              <MoveHorizontal class="size4" />
            </Button>

            <Button size="icon" variant="outline" @click="layoutGraph('TB')">
              <MoveVertical class="size4" />
            </Button>

            <!-- <Button size="icon" variant="outline" @click="shuffleGraph">
              <Shuffle class="size4" />
            </Button> -->
          </div>
        </div>
      </Panel>
    </VueFlow>
  </div>
</template>

<style>
@import '@vue-flow/core/dist/style.css';
@import '@vue-flow/core/dist/theme-default.css';

.process-panel label {
  color: white;
  font-size: 12px;
}

.stop-btn svg {
  display: none;
}

.stop-btn:hover svg {
  display: block;
}

.stop-btn:hover .spinner {
  display: none;
}

.spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #2563eb;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
