<script setup>
import { computed, toRef } from 'vue'
import { PocketKnife, Check } from 'lucide-vue-next'
import { Handle, useHandleConnections } from '@vue-flow/core'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  sourcePosition: {
    type: String,
  },
  targetPosition: {
    type: String,
  },
})

const sourceConnections = useHandleConnections({
  type: 'target',
})

const targetConnections = useHandleConnections({
  type: 'source',
})

const isSender = toRef(() => sourceConnections.value.length <= 0)

const isReceiver = toRef(() => targetConnections.value.length <= 0)

const bgColor = computed(() => {
  // if (isSender.value) {
  //   return '#2563eb'
  // }

  if (props.data.isRunning) {
    return 'rgba(37, 99, 235, 0.3)'
  }

  if (props.data.hasError) {
    return '#f87171'
  }

  if (props.data.isFinished) {
    return 'rgba(66, 185, 131, 0.3)'
  }

  if (props.data.isCancelled) {
    return 'rgba(251, 191, 36, 0.3)'
  }

  return 'hsl(var(--muted))'
})

const processLabel = computed(() => {
  if (props.data.hasError) {
    return '❌'
  }

  if (props.data.isSkipped) {
    return '🚧'
  }

  if (props.data.isCancelled) {
    return '🚫'
  }

  if (isSender.value) {
    return '📦'
  }

  if (props.data.isFinished) {
    return '😎'
  }

  return '🏠'
})
</script>

<template>
  <div
    class="process-node flex flex-col p-4 rounded-lg hover:shadow-lg bg-muted border relative"
    :style="{
      backgroundColor: bgColor,
      // boxShadow: data.isRunning ? '0 0 10px rgba(0, 0, 0, 0.5)' : '',
    }"
  >
    <div
      v-if="data.isFinished"
      class="absolute top-1 right-1 size-4 rounded-full bg-blue-500 flex items-center justify-center"
    >
      <Check class="size-3 text-white" />
    </div>
    <Handle v-if="!isSender" type="target" :position="targetPosition">
      <span
        v-if="
          !data.isRunning &&
          !data.isFinished &&
          !data.isCancelled &&
          !data.isSkipped &&
          !data.hasError
        "
        >📥
      </span>
    </Handle>

    <Handle v-if="!isReceiver" type="source" :position="sourcePosition" />

    <!-- <div v-if="!isSender && data.isRunning" class="spinner" />
    <span v-else>
      {{ processLabel }}
    </span> -->
    <span class="text-base mb-1">{{ data.label }}</span>
    <div v-if="data.tools" class="text-sm bg-white rounded-md p-2 border">
      <div v-if="data.tools && data.tools.dataArrInfo">
        <div v-for="(item, index) in data.tools.dataArrInfo" :key="index" class="mb-2">
          <h3 class="font-bold">{{ item.title }}</h3>
          <div v-if="item.type === 'key'">
            <table :border="true">
              <tr v-for="(tb, tbIndex) in item.data" :key="tbIndex" class="mb-3">
                <th>{{ tb.key }}</th>
                <td>{{ tb.value }}</td>
              </tr>
            </table>
          </div>
          <div v-if="item.type === 'table'">
            <table :border="true">
              <tr>
                <th>类型</th>
                <th>参数名称</th>
                <th>中文释义</th>
              </tr>
              <tr v-for="(tb, tbIndex) in item.data" :key="tbIndex">
                <td>{{ tb.type }}</td>
                <td>{{ tb.name }}</td>
                <td>{{ tb.caption }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div v-else-if="typeof data.tools === 'string'">
        <p class="flex items-center gap-1 texs-xs mb-1">
          <PocketKnife class="size-4" />
          工具
        </p>
        {{ JSON.parse(data.tools)?.function?.name }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.process-node .vue-flow__handle {
  border: none;
  height: unset;
  width: unset;
  background: transparent;
  font-size: 12px;
}
</style>
