<template>
  <div class="h-full w-full">
    <XyFlow
      v-show="
        props.compilerData &&
        props.compilerData.processName &&
        !props.compilerData.processName.includes('test-etl-')
      "
      v-model:nodes="nodes"
      v-model:edges="edges"
      :flow-data="[]"
    ></XyFlow>

    <XyFlow
      v-show="
        props.compilerData &&
        props.compilerData.processName &&
        props.compilerData.processName.includes('test-etl-')
      "
      v-model:nodes="nodes2"
      v-model:edges="edges2"
      :flow-data="[]"
    ></XyFlow>
  </div>
</template>

<script setup lang="ts">
const nodes = ref<any[]>([])
const edges = ref<any[]>([])
const position = { x: 0, y: 0 }
const nodeType = 'process'
const transformStepsToFlowData = (steps: any[]) => {
  const nodes: any[] = []
  const edges: any[] = []

  steps.forEach((item, index) => {
    // console.log(JSON.parse(item.tools), '123123123213')
    // 处理节点
    nodes.push({
      id: item.id.toString(),
      position,
      type: nodeType,
      data: { label: `${index + 1}. ${item.stepDesc}`, tools: item.tools },
    })

    // 处理边（如果不是最后一个节点）
    if (steps[index + 1]) {
      edges.push({
        id: index.toString(),
        source: item.id.toString(),
        target: steps[index + 1].id.toString(),
        type: 'animation',
      })
    }
  })
  // console.log(nodes, edges, 'nodes, edges')
  return { nodes, edges }
}
import XyFlow from '@/components/XyFlow/index.vue'

const nodes2 = ref<any[]>([])
const edges2 = ref<any[]>([])
const transformStepsToFlowData2 = (nodeData: any[]) => {
  const nodes2: any[] = []
  const edges2: any[] = []
  if (nodeData && nodeData.nodes && nodeData.nodes.length) {
    nodeData.nodes.forEach((item, index) => {
      const nodedata: any = JSON.parse(item.nodeInfo)

      const dataArrInfo = []
      if (nodedata.properties.dataBase) {
        dataArrInfo.push({
          title: '',
          type: 'key',
          data: [
            {
              key: '库名：',
              value: nodedata.properties.dataBase,
            },
          ],
        })
      }
      if (nodedata.properties._parameter_table) {
        dataArrInfo.push({
          title: '',
          type: 'key',
          data: [
            {
              key: '表名：',
              value: nodedata.properties._parameter_table,
            },
          ],
        })
      }
      if (nodedata.insert_data.properties) {
        dataArrInfo.push({
          title: '',
          type: 'key',
          data: [
            {
              key: '写入模式：',
              value: nodedata.insert_data.properties._parameter_sinkType,
            },
            {
              key: '写入表名：',
              value: nodedata.insert_data.properties._parameter_table,
            },
          ],
        })
      }
      if (nodedata.insert_data.schema) {
        dataArrInfo.push({
          title: '',
          type: 'table',
          data: nodedata.insert_data.schema,
        })
      }
      // 处理节点
      nodes2.push({
        id: item.id.slice(4).toString(),
        position,
        type: nodeType,
        data: {
          label: `${index + 1}. ${nodedata.name}`,
          tools: {
            dataArrInfo: dataArrInfo,
          },
        },
      })

      // 处理边（如果不是最后一个节点）
      if (nodeData.nodes[index + 1]) {
        edges2.push({
          id: index.toString(),
          source: item.id.slice(4).toString(),
          target: nodeData.nodes[index + 1].id.slice(4).toString(),
          type: 'animation',
        })
      }
    })
  }
  return { nodes2, edges2 }
}
const props = defineProps({
  compilerData: {
    required: true,
    type: Object,
    default: () => {},
  },
  nodeInfo: {
    required: true,
    type: Object,
    default: () => {},
  },
})

const setFlow = (val) => {
  nodes.value = []
  edges.value = []
  if (val.stepInfo && val.stepInfo.length && val.stepInfo.every((item) => item.id)) {
    const { nodes: nodesData, edges: edgesData } = transformStepsToFlowData(val.stepInfo)
    nodes.value = nodesData
    edges.value = edgesData
  }
}

const setFlow2 = (val) => {
  nodes2.value = []
  edges2.value = []

  const { nodes2: nodesData, edges2: edgesData } = transformStepsToFlowData2(val)
  nodes2.value = nodesData
  edges2.value = edgesData
}

watch(
  () => props.compilerData,
  async (val) => {
    await nextTick()
    setFlow(val)
  },
  { deep: true },
)

watch(
  () => props.nodeInfo,
  async (val) => {
    await nextTick()
    setFlow2(val)
  },
  { deep: true },
)
</script>

<style scoped lang="scss"></style>
