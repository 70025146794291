import { Hover } from '@/components/Hover'
import { Shared } from '@/components/Shared'
import { useResponsive } from '@/hooks/useResponsive'
import { Search, MessageSquarePlus } from 'lucide-vue-next'
import { chatStore } from '@/store'

export const TopBar = defineComponent({
  name: 'TopBar',
  emits: ['search', 'create'],
  setup(_props, { attrs, emit }) {
    const createHanle = () => emit('create')
    const { isMobile } = useResponsive()
    const title = window.VITE_TITLE || '博闻智星'
    const chatstore = chatStore()
    const testHandle = () => {
      chatstore.startLoadingToWork('work')
    }

    const testHandle2 = () => {
      chatstore.startLoadingToWork('front')
    }
    return () => (
      <div class='p-4 flex flex-col gap-4'>
        <div class='flex justify-between items-center'>
          <div class='text-base lg:text-xl font-bold tracking-wide'>
            <span>{title}</span>
            {/* <span onClick={testHandle}>{title}</span>
            <span onClick={testHandle2}>前台页面</span> */}
          </div>
          <div class='flex items-center gap-1'>
            {isMobile.value && <Shared />}
            <Hover content='新会话' onClick={createHanle}>
              <MessageSquarePlus class='w-5 h-5 lg:w-6 lg:h-6' />
            </Hover>
          </div>
        </div>
        <n-input clearable placeholder='筛选智能体...' {...attrs}>
          {{
            prefix: () => (
              <n-icon>
                <Search />
              </n-icon>
            ),
          }}
        </n-input>
      </div>
    )
  },
})
