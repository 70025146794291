import { v4 as uuidv4 } from 'uuid'
import { storeToRefs } from 'pinia'
import { UploadFileInfo } from 'naive-ui'
import { useScroll } from '@/hooks/useScroll'
import { themeStore, chatStore } from '@/store'
import { fileToBase64, isFileOrBlobInstance, isZeroWidthSpace } from '@/utils'
import { ImageMimeTypes, MimeType } from '@/contants/mimeTypes'
import { useChatStrategies, ChatHook } from '@/chat-strategies/hooks'
import { useTopic, CHAT_PROVIDER, ChatProvide } from '@/hooks/useTopic'

/**
 * @description 聊天提供者,使用同一个聊天提供者,可以切换不同聊天策略
 */
export const ChatProvider = defineComponent({
  name: 'ChatProvider',
  setup(_props, { slots }) {
    /**
     * @description 输入框内容(提问内容)
     */
    const message = ref('')
    /**
     * @description 聊天上传可附件(可选)
     */
    const { isHistoryLimit, chatHistoryLimit } = storeToRefs(themeStore())

    const {
      isAbort,
      activeAgent,
      activeTopic,
      updateTopic,
      createTopic,
      switchTopic,
      deleteTopic,
      favoriteTopic,
      updateTopicName,
      getTopicByName,
      deleteAllTopic,
      deleteUnFavorite,
    } = useTopic()

    /**
     * @description  切换activeTopic,滚动到底部
     */
    const { scrollRef, isAtBottom, scrollToBottom } = useScroll()
    watch(
      () => activeTopic.value,
      async () => {
        await nextTick()
        scrollToBottom()
      },
    )
    /**
     * @description 聊天策略, 根据不同的类型的技能, 使用不同的聊天策略
     */
    const { chatHooks } = useChatStrategies({ activeAgent })
    const activeChatHandle = ref<ChatHook>()
    watch(
      () => chatHooks.value,
      async () => {
        const res = chatHooks.value({
          isAbort,
          message,
          activeAgent: computed(() => activeAgent.value),
          activeTopic: computed(() => activeTopic.value),
          updateTopic,
          scrollToBottom,
        })
        activeChatHandle.value = res
      },
      { deep: true, immediate: true, flush: 'post' },
    )
    /**
     * @description 用户发送消息时, 想当前会话(主题)添加一条消息
     */
    const addUserMessage: ChatProvide['addUserMessage'] = async (params) => {
      if (params.msg) {
        const name = params.topicName || params.msg
        if (activeAgent.value.type !== 'human-chat') {
          // 专家坐席时,不需要创建新会话历史
          createTopic(name)
        }
        const files = await handleFile(params.attachments || [])
        const message: IChatMessage[] = [
          {
            id: uuidv4(),
            role: 'userMessage',
            content: params.msg,
            date: new Date().toString(),
            attachments: files,
          },
        ]
        activeTopic.value?.messages.push(...message)
        deleteLastTopic()
      }
    }
    /**
     * @description 添加一条ai回答的占位消息, 后续返回时填充ai消息
     */
    const addAIMessage: ChatProvide['addAIMessage'] = () => {
      const message: IChatMessage[] = [
        {
          id: uuidv4(),
          role: 'apiMessage',
          content: '',
          date: new Date().toString(),
          isRead: false,
        },
      ]
      activeTopic.value?.messages.push(...message)
    }

    /**
     * @description 往当前会话添加提示消息(TipsMessage)
     */
    const addTipsMessage: ChatProvide['addTipsMessage'] = (msg) => {
      const message: IChatMessage[] = [
        {
          id: uuidv4(),
          role: 'tipsMessage',
          content: msg,
          date: new Date().toString(),
        },
      ]
      activeTopic.value?.messages.push(...message)
    }

    /**
     * @description 发送提问, 调用会话接口
     * @param {string} topicName 会话名称,可选
     */
    const chatstore = chatStore()
    const sendChatMessage: ChatProvide['sendChatMessage'] = (params) => {
      if (message.value) {
        let msg: string
        isZeroWidthSpace(message.value) ? (msg = '附件解析') : ''
        window.$useAuth(async () => {
          await addUserMessage({
            msg: message.value, // \u200B 零宽字符
            topicName: params?.topicName || msg,
            attachments: params?.attachments,
          })
          addAIMessage()
          scrollToBottom()
          unref(activeChatHandle)?.sendMessage()
        })
        // addUserMessage({
        //   msg: message.value,
        //   topicName: params?.topicName,
        //   attachments: params?.attachments,
        // })
        // addAIMessage()
        // scrollToBottom()
        // unref(activeChatHandle)?.sendMessage()
      }
    }
    /**
     * @description 如果当前提问有附件存在, message 为空, 则给message 设置一个零款字符串，绕过验证
     */
    const setDefaultMessage = (attachments: UploadFileInfo[] | undefined) => {
      if (attachments && attachments.length > 0 && !message.value) {
        message.value = '\u200B'
      }
    }

    /**
     * @description 取消回答
     */
    const cancleStream = () => {
      !isAbort.value && activeChatHandle.value?.stopStream()
      isAbort.value = true
    }

    /**
     * @description 处理附件信息, 如果是图片类型直接base64存储, 否则只存储文件基本信息
     */
    const handleFile = async (files: UploadFileInfo[]) => {
      const filesList = []
      for await (const item of files) {
        const info = {
          file: '',
          name: item.name,
          size: item.file?.size,
          type: item.type,
        }
        if (ImageMimeTypes.includes(item.type as MimeType)) {
          if (isFileOrBlobInstance(item.file)) {
            info.file = await fileToBase64(item.file) // 如果是图片资源直接存储文件
          }
        }
        filesList.push(info)
      }
      return filesList
    }
    /**
     * @description 如果限制了会话数量,则删除最后一个会话
     */
    const deleteLastTopic = () => {
      const isLimit = activeAgent.value!.children?.length > chatHistoryLimit.value + 1
      if (isHistoryLimit.value && isLimit) {
        activeAgent.value!.children.splice(chatHistoryLimit.value + 1, 1)
      }
    }

    provide<ChatProvide>(CHAT_PROVIDER, {
      scrollRef,
      isAtBottom,
      isAbort,
      message,
      addUserMessage,
      addTipsMessage,
      addAIMessage,
      activeAgent,
      activeTopic,
      updateTopic,
      createTopic,
      switchTopic,
      deleteTopic,
      favoriteTopic,
      updateTopicName,
      scrollToBottom,
      sendChatMessage,
      activeChatHandle,
      cancleStream,
      getTopicByName,
      deleteAllTopic,
      deleteUnFavorite,
    })
    return () => <>{slots.default?.()}</>
  },
})
