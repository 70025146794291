<template>
  <DefineTemplate v-slot="{ title, icon, onClick }">
    <Tooltip :title="title">
      <span
        class="hover:bg-neutral-200/60 dark:hover:bg-neutral-800 rounded-sm p-1"
        @click="onClick"
      >
        <component :is="icon" class="w-4 h-4" />
      </span>
    </Tooltip>
  </DefineTemplate>
  <div class="terminal-container h-full flex flex-col">
    <div class="terminal-header">
      <span class="flex items-center gap-2 select-none text-sm font-medium">
        <SquareTerminal class="w-4 h-4" /> 终端
      </span>

      <div class="ml-auto flex gap-3">
        <div v-if="route.name === 'Develop'" class="flex items-center ml-2 h-full">
          <div
            class="cursor-pointer px-2"
            :class="{ active: ctive === 'rz' }"
            @click="changective('rz')"
          >
            日志
          </div>
          <div
            class="cursor-pointer px-2"
            :class="{ active: ctive === 'lc' }"
            @click="changective('lc')"
          >
            流程
          </div>
        </div>
        <ReuseTemplate title="新建终端" :icon="Plus" @click="() => console.log('新建终端')" />
        <ReuseTemplate title="终止终端" :icon="Trash2" @click="() => console.log('终止终端')" />
        <ReuseTemplate title="最大化" :icon="ChevronUp" @click="() => console.log('最大化')" />
        <ReuseTemplate title="隐藏面板" :icon="X" @click="() => console.log('隐藏面板')" />
        <ReuseTemplate title="清空" :icon="Eraser" @click="() => emit('clear')" />
      </div>
    </div>
    <div v-show="ctive === 'rz'" ref="terminalElement" class="terminal-content">
      <div v-for="item in logArr" :key="item.content" class="mb-1 flex items-center flex-wrap">
        <!-- 执行时间 -->
        <template v-if="item.displayType === 'json'">
          <Jsons :json="item.content" />
        </template>
        <template v-else>
          <span class="text-xs text-neutral-500 dark:text-neutral-400 mr-1">{{ item.time }}:</span>
          <!-- 替换原来的span,根据type使用对应的ColorText组件 -->
          <Red v-if="item.type === 'error'" block>{{ item.content }}</Red>
          <Green v-else-if="item.type === 'success'" block>{{ item.content }}</Green>
          <Blue v-else-if="item.type === 'primary'" block>{{ item.content }}</Blue>
          <Gray v-else block>{{ item.content }}</Gray>
        </template>
      </div>

      <!-- <Red bold>这是粗体红色文本</Red>
      <Green italic>这是斜体绿色文本</Green>
      <Yellow bold italic>这是粗斜体黄色文本</Yellow>
      <Gray>这是灰色文本</Gray>
      <Blue v-for="i in 19" :key="i" block>这是蓝色文本{{ i }}</Blue> -->
    </div>
    <Flow
      v-show="ctive === 'lc'"
      :compiler-data="props.compilerData"
      :node-info="props.nodeInfo"
    ></Flow>
  </div>
</template>

<script setup lang="ts">
import Tooltip from '@/components/Tooltip/index.vue'
import { createReusableTemplate } from '@vueuse/core'
import { Blue, Red, Green, Yellow, Gray } from './widgets/ColorText'
import { SquareTerminal, Trash2, X, ChevronUp, Plus, Eraser } from 'lucide-vue-next'
import Jsons from '@/components/Jsons/index.vue'
const route = useRoute()
import Flow from './widgets/flow.vue'
const emit = defineEmits(['clear'])
export interface LogItem {
  time: string
  content: string
  type: 'info' | 'primary' | 'success' | 'error'
  displayType?: 'string' | 'json'
}

const props = defineProps({
  compilerData: {
    required: true,
    default: () => {},
  },
  nodeInfo: {
    required: true,
    type: Object,
  },
})

const ctive = ref('rz')

const changective = (type) => {
  ctive.value = type
}

const [DefineTemplate, ReuseTemplate] = createReusableTemplate()
const terminalElement = ref<HTMLElement | null>(null)

const logArr = defineModel<LogItem[]>({ default: [] })
watch(
  logArr,
  async () => {
    await nextTick()
    if (terminalElement.value) {
      terminalElement.value.scrollTop = terminalElement.value.scrollHeight
    }
  },
  { deep: true },
)

const isMinimized = ref(false)
const toggleTerminal = () => {
  isMinimized.value = !isMinimized.value
}
</script>

<style scoped>
.terminal-content {
  @apply transition-all duration-300 overflow-auto relative flex-1 px-4 py-1 h-[calc(100%-2rem)];
}
.terminal-header {
  @apply flex items-center px-4 py-1 border-y bg-background;
}
.cursor {
  @apply absolute left-5 top-2 w-1 h-4 bg-neutral-400;
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.xterm-viewport {
  background-color: transparent!;
}

.active {
  border-bottom: 1px solid #ccc;
}
</style>
