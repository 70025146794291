import { chatStore } from '@/store'
export default defineComponent({
  name: 'DashboardProvider',
  setup(_props, { slots }) {
    const router = useRouter()
    const route = useRoute()
    const chatstore = chatStore()

    const taskId = ref('')
    const setTaskId = (id: string) => {
      taskId.value = id
      if (route.name === 'Develop') {
        router.push('/')
      }
      chatstore.startLoadingToWork('front')
    }
    const openWork = () => {
      if (route.name !== 'Develop') {
        router.push('/develop')
      }
      chatstore.startLoadingToWork('work')
    }
    const flowData = ref([])
    provide('dashboard', { taskId, setTaskId, openWork, flowData })
    const test = () => {
      console.log(
        { taskId, setTaskId, openWork, flowData },
        chatstore,
        '{ taskId, setTaskId, openWork, flowData }{ taskId, setTaskId, openWork, flowData }',
      )
    }
    return () => <>{slots.default?.()}</>
  },
})
