/* eslint-disable camelcase */
import { textToImage, createCall, fetchChatAPIStream1, getPluginById } from '@/api'
import { ChatHookFn, ChatHookParams, ChatParams } from '..'
import {
  getPluginTitle,
  getPluginDesc,
  getPluginNameByIdentifier,
  getMediaMessages,
  handleToolCallsResponse,
} from './helpers'
import { genToolCallingName } from './toolCall'
import { storeToRefs } from 'pinia'
import { llmParamsStore, chatStore, devlopStore } from '@/store'
import { ImageMimeTypes, MimeType } from '@/contants/mimeTypes'
import { fileToBase64, getBase64Image, isFileOrBlobInstance, isObject, sleep } from '@/utils'
import { v4 as uuidv4 } from 'uuid'
import type { ChatQuery, ImageSchema, Tool, ImageMessage } from './types'
import { StreamProcessor } from './StreamProcessor'
import { PluginRequestPayload } from '@rzx/chat-plugin-sdk'
import { getNodeInfo as getNodeInfoApi } from '@/api/resouce'

const CHAT_CONFIG = {
  MAX_CONCURRENT_CHATS: 3,
  CHAT_FLOW_ID: import.meta.env.VITE_CHAT_FLOW_ID,
  MODEL: 'gpt-40',
  TYPING_DELAY: { min: 10, max: 20 },
  TEMPERATURE: 0.6,
  TOP_P: 1,
} as const

/**
 *@description 插件模式会话逻辑
 */
export const usePluginChat: ChatHookFn = ({
  isAbort,
  message,
  activeTopic,
  activeAgent,
  updateTopic,
  scrollToBottom,
}: ChatParams) => {
  let controller = new AbortController()
  const { file } = storeToRefs(llmParamsStore())
  const developstore = developStore()
  const dashboardRef = inject<any>('dashboard')
  const route = useRoute()
  console.log(dashboardRef, '11111111111111')

  const activeIndex = ref<number | undefined>(undefined) //会话内容的索引, 默认是会后一条回答

  const isAllImage = computed(() =>
    file.value?.every((item) => ImageMimeTypes.includes(item.type as MimeType)),
  )
  /**
   * @description 发送(重试)会话信息
   * @param object {index: number} 重试回答是的信息所在索引
   */
  const getNodeInfo = async () => {
    const res = await getNodeInfoApi()
    if (res.code === 1) {
      developstore.nodeInfo.value = res.data
    } else {
      window.$message.error(res.msg || '未知错误')
    }
  }
  const sendMessage = async ({ index, loacl }: ChatHookParams = {}) => {
    if (activeAgent.value?.agentId) {
      console.log('activeAgent.value?.agentId, 有id', activeAgent.value?.agentId)
    }
    controller = new AbortController()
    isAbort.value = false
    const { tools, plugins } = await handleParmas()
    const str = enabledSysRoles(plugins)

    const len = activeTopic.value!.messages.length
    activeIndex.value = index ?? len - 1
    const messages = activeTopic.value!.messages[activeIndex.value]
    messages.content = ''
    messages.status = 2

    const historyMsg = await getHistoryMsg(activeTopic.value!, activeIndex.value)
    const files = !isAllImage.value ? file.value?.map((item) => item.file) || [] : []

    const params: ChatQuery = {
      question: message.value,
      frequency_penalty: 0,
      messages: historyMsg,
      model: 'gpt-4o',
      presence_penalty: 0,
      stream: true,
      temperature: 0.6,
      tools,
      top_p: 1,
      files,
      chatFlowId: activeAgent.value?.agentId,
    }
    message.value = ''
    try {
      const response = await fetchChatAPIStream1({
        data: params,
        signal: controller.signal,
      })

      const processor = new StreamProcessor({
        onStart: () => {
          messages.content = ''
          messages.status = 2
        },
        onToken: async (content) => {
          messages.status = !messages.content ? 2 : 0
          const chars = content.split('')
          for (const char of chars) {
            messages.content += char
            await sleep(
              Math.random() * (CHAT_CONFIG.TYPING_DELAY.max - CHAT_CONFIG.TYPING_DELAY.min) +
                CHAT_CONFIG.TYPING_DELAY.min,
              controller.signal,
            )
            scrollToBottom?.()
          }
        },
        onToolCall: (toolCalls) => {
          const payload = handleToolCallsResponse(toolCalls)

          messages.toolCalls = payload
          for (const tool of toolCalls) {
            if (tool.function?.arguments) {
              messages.prompt += tool.function.arguments
              messages.toolCalls.arguments += tool.function.arguments
              if (payload.identifier === 'register-data-source-test') {
                messages.content += payload.arguments
                messages.plugin = payload.identifier
              } else {
                messages.status = 2
                messages.content = payload.arguments
                messages.role = 'promptMessage'
              }
              scrollToBottom?.()
            }
          }
        },
        onError: (error) => {
          if (!messages.content) {
            messages.status = 1
            messages.content = error?.message || '模型服务好像出小差了, 请重试!!🚧🚧🚧'
          }
          updateContent()
        },
        onFinish: async () => {
          messages.status = 0
          if (messages.toolCalls) {
            const { identifier } = messages.toolCalls

            if (identifier === 'enter-develop') {
              // const chatstore = chatStore()
              // chatstore.startLoadingToWork('work')
              // console.log(dashboardRef, '2222222222222')
              dashboardRef.openWork()
            }

            // 根据 identifier 查找plugins对应的工具的ui对象
            // console.log(plugins, identifier, 'identifieridentifier identifieridentifier')
            const hasIframe = plugins.find((item) => item.identifier === identifier)?.ui?.mode

            if (hasIframe) {
              // 获取arguments
              console.log(messages.toolCalls.arguments, 'argumentsarguments')
              try {
                const args = JSON.parse(messages.toolCalls.arguments)
                dashboardRef.setTaskId(args?.id)
              } catch (err) {
                console.log(err, '错误错误错误错误')
                messages.status = 1
                messages.content = '跳转失败' + err || '模型服务好像出小差了, 请重试!!🚧🚧🚧'
                updateContent()
              }
            }

            if (identifier === 'lobe-image-designer') {
              const { prompts, size = '' } = JSON.parse(messages.content!)
              textToImageLogic(prompts, size)
            } else {
              handlePluginCall(messages.toolCalls)
            }
          }
          updateContent()
        },
      })

      await processor.processStream(response)
    } catch (error: any) {
      messages.status = 1
      messages.content = error.message || '请求失败,请重试!'
      updateContent()
    }
  }
  /**
   * @description 文生图逻辑
   */
  const textToImageLogic = async (prompts: string[], size: string) => {
    activeTopic.value!.messages.push({
      date: new Date().toISOString(),
      role: 'apiMessage',
      content: '生成图片中...',
      prompt: JSON.stringify(prompts),
      status: 0,
      mediaMessage: [] as unknown as MediaMessage<ImageSchema>[],
    })
    const len = activeTopic.value!.messages.length
    const topic = activeTopic.value!.messages[len - 1]

    const promises = prompts.map(async (prompt: string) => {
      topic.mediaMessage!.push({ type: 'image', data: { url: '', placeholder: prompt } })
      const res = await textToImage({ prompt, size })
      const resStr = res.replace('data:', '')
      try {
        const result = JSON.parse(resStr)

        if (!result.error) {
          const data_1 = result.data[0]
          const base64Str = await getBase64Image(data_1.url)
          topic.mediaMessage!.forEach((item: MediaMessage<ImageSchema>) => {
            if (item.data.placeholder === prompt) {
              item.data.url = base64Str
            }
          })
        } else {
          const { message } = result.error
          topic.mediaMessage!.forEach((item_1: MediaMessage<ImageSchema>) => {
            if (item_1.data.placeholder === prompt) {
              item_1.data.placeholder = prompt
              item_1.data.error = message
            }
          })
        }
      } catch (error) {
        console.log(error)
      }
    })
    scrollToBottom()
    await Promise.allSettled(promises)
    topic.content = '为你生成如下图片'
    updateTopic()
  }
  /**
   * @description 其他插件调用逻辑
   * @param id 插件调用id, 模型返回
   * @param pluginIdentifier 提问时手动生成的插件标识符
   * @param paramsStr 插件方法调用的参数字符串
   */
  const handlePluginCall = async (payload: PluginRequestPayload) => {
    const len = activeTopic.value!.messages.length
    const lastTopic = activeTopic.value!.messages[len - 1]
    lastTopic.role = 'apiMessage'
    lastTopic.status = 2
    const { identifier, id } = payload
    try {
      developstore.nodeInfo.value = {}
      const query = {
        history: await getHistoryMsg(activeTopic.value!, activeIndex.value),
        ...payload,
      }
      const res = await createCall(query)
      if (res.code === 1) {
        // test-etl-
        // if (identifier.includes('register-data-source-test')) {
        //   getNodeInfo()
        // }
        // console.log(identifier.includes('register-data-source-test'), 'identifieridentifier')

        switch (identifier) {
          case identifier.startsWith('test-etl') ? identifier : 'test-etl-outers':
            getNodeInfo()
            break
          case identifier.startsWith('database') ? identifier : 'database-search':
            handleDatabaseSearch(res.data)
            break
          case 'knowledge-search':
            handleKnowledgeSearch(res.data)
            break
          case 'law-video':
            handleLawVideo(res.data)
            break
          case 'warn-search':
            handleWarnSearch(res.data)
            break
          case 'register-service':
            addMessage('toolMessage', {
              content: JSON.stringify(res.data),
              name: identifier,
              tool_call_id: id,
            })
            addMessage('apiMessage', { prompt: JSON.stringify(res.data) })
            sendMessage()
            break
          default:
            // 原有的处理逻辑
            addMessage('toolMessage', {
              content: JSON.stringify(res.data),
              name: identifier,
              tool_call_id: id,
            })
            addMessage('apiMessage', { prompt: JSON.stringify(res.data) })
            sendMessage()
        }
      } else {
        addMessage('apiMessage', {
          content: res.msg || '未知错误',
          status: 1,
        })
        updateTopic()
      }
    } catch (error: any) {
      addMessage('apiMessage', { content: JSON.stringify({ message: error.msg }), status: 1 })
      updateTopic()
    } finally {
      lastTopic.status = 0
      lastTopic.role = 'promptMessage'
    }
  }
  function handleDatabaseSearch(data: any) {
    const res = data[0]
    addMessage('apiMessage', { content: res.text, mediaMessage: getMediaMessages(res), status: 0 })
    updateTopic()
  }

  function handleKnowledgeSearch(data: any) {
    const text = data[0].text
    addMessage('apiMessage', { content: text, status: 0 })
    updateTopic()
  }

  function handleWarnSearch(data: any) {
    addMessage('apiMessage', {
      content: '',
      mediaMessage: [{ type: 'warn', data: '' }],
      status: 0,
    })
    updateTopic()
  }

  function handleLawVideo(data: any) {
    const res = data[0]
    const messages = {
      content: '',
      mediaMessage: [] as unknown as MediaMessage<ImageSchema>[],
    }
    if (res.type === '6') {
      messages.content = '正在尝试连接记录仪...'
      messages.mediaMessage?.push({
        type: 'video',
        data: res,
      })
    } else if (res.type === '5') {
      messages.content = '正在尝试连接记录仪...'
      messages.mediaMessage?.push({
        type: 'image',
        data: res,
      })
    } else if (res.type === '4') {
      messages.content = '正在尝试连接记录仪...'
      messages.mediaMessage?.push({
        type: 'location',
        data: res,
      })
    }
    addMessage('apiMessage', { ...messages, status: 0 })
    updateTopic()
  }
  /**
   * @description 根据pluginSchema, 处理参数
   */

  //  else if (
  //     route.name === 'Develop' &&
  //     developstore.curtTools &&
  //     developstore.curtTools.value &&
  //     developstore.curtTools.value.length
  //   ) {
  //     // 测试插件模式
  //     const res: any = await getPluginById({
  //       id: 151,
  //     })
  //     if (res.code === 1) {
  //       try {
  //         const schemas: IPlugin[] = [{ content: res.data.content }].filter(Boolean)
  //         tools = schemas
  //           .map((item: any) => {
  //             const plugin: PluginSchema = JSON.parse(item.content)
  //             plugins.push(plugin)
  //             const functions = plugin?.api?.map((ele) => {
  //               return {
  //                 function: {
  //                   name: `${plugin.identifier}__${ele.name}`,
  //                   description: ele.description,
  //                   parameters: ele.parameters,
  //                 },
  //                 type: 'function',
  //               }
  //             })
  //             return functions
  //           })
  //           .filter(Boolean)
  //           .flat(1)
  //       } catch (error) {
  //         console.error('插件参数解析失败', error)
  //       }
  //     } else {
  //       tools = []
  //     }
  //   }
  const handleParmas = async () => {
    const { pluginDetail } = activeAgent.value!
    let tools: Tool[] = []
    const plugins: PluginSchema[] = []
    if (activeAgent.value.type === 'test-plugin-chat') {
      // 测试插件模式
      const res: any = await getPluginById({
        id: activeAgent.value.pluginDetail
          ? JSON.parse(activeAgent.value.pluginDetail)[0].pluginId
          : '',
      })
      if (res.code === 1) {
        try {
          const schemas: IPlugin[] = [{ content: res.data.content }].filter(Boolean)
          tools = schemas
            .map((item: any) => {
              const plugin: PluginSchema = JSON.parse(item.content)
              plugins.push(plugin)
              const functions = plugin?.api?.map((ele) => {
                return {
                  function: {
                    name: `${plugin.identifier}__${ele.name}`,
                    description: ele.description,
                    parameters: ele.parameters,
                  },
                  type: 'function',
                }
              })
              return functions
            })
            .filter(Boolean)
            .flat(1)
        } catch (error) {
          console.error('插件参数解析失败', error)
        }
      } else {
        tools = []
      }
    } else if (pluginDetail) {
      try {
        const schemas: IPlugin[] = JSON.parse(pluginDetail).filter(Boolean)
        tools = schemas
          .map((item: any) => {
            const plugin: PluginSchema = JSON.parse(item.content)
            plugins.push(plugin)
            const functions = plugin?.api?.map((ele) => {
              return {
                function: {
                  name: `${plugin.identifier}__${ele.name}`,
                  description: ele.description,
                  parameters: ele.parameters,
                },
                type: 'function',
              }
            })
            return functions
          })
          .filter(Boolean)
          .flat(1)
      } catch (error) {
        console.error('插件参数解析失败', error)
      }
    }
    return { tools, plugins }
  }
  /**
   * @description 对话历史插入插件描述信息
   */
  const enabledSysRoles = (plugins: PluginSchema[]) => {
    console.log(plugins, 'pluginpluginplugin')
    const toolsSystemRole = plugins
      .map((plugin) => {
        const meta = plugin?.meta || {}
        const title = getPluginTitle(meta) || plugin?.identifier
        const systemRole = plugin?.systemRole || getPluginDesc(meta)
        const methods = plugin?.api
          .map((m) =>
            [
              `#### ${genToolCallingName(plugin?.identifier, m.name, plugin.type)}`,
              m.description,
            ].join('\n\n'),
          )
          .join('\n\n')

        return [`### ${title}`, systemRole, 'The APIs you can use:', methods].join('\n\n')
      })
      .filter(Boolean)
    if (toolsSystemRole.length > 0) {
      return ['## Tools', 'You can use these tools below:', ...toolsSystemRole]
        .filter(Boolean)
        .join('\n\n')
    }

    return ''
  }

  /**
   * @description 获取历史会话上下文
   * @param {ITopic} topicData
   */
  const getHistoryMsg = async (topicData: ITopic, index: number | undefined) => {
    const data = index !== undefined ? topicData.messages.slice(0, index) : topicData.messages
    const historyMsg = []
    for await (const item of data) {
      let ele
      if (item.attachments?.length) {
        const content = await parseImageMessage(item)
        ele = { content, role: item.role }
      } else if (item.role === 'toolMessage') {
        ele = {
          content: item.content,
          role: item.role,
          name: item.name,
          tool_call_id: item['tool_call_id'],
        }
      } else {
        ele = { content: item.content, role: item.role }
      }
      historyMsg.push(ele)
    }
    return historyMsg
  }
  const stopStream = () => {
    controller?.abort()
    const len = activeTopic.value!.messages.length
    activeIndex.value = activeIndex.value || len - 1
    const messages = activeTopic.value!.messages[activeIndex.value]
    messages.status = 0
    !messages.content && (messages.content = '已手动中止回答')
    updateContent()
  }
  /**
   * @description 手动添加消息
   */
  const addMessage = (role: IChatRole, message: string | Record<string, any> = '') => {
    let messageItem: IChatMessage = {
      id: uuidv4(),
      role,
      content: '',
      date: new Date().toString(),
    }
    if (isObject(message)) {
      messageItem = Object.assign(messageItem, message)
    } else {
      messageItem.content = message
    }
    activeTopic.value!.messages.push(messageItem)
  }

  const getMainfestByIdentifier = (identifier: string) => {
    const { pluginDetail } = activeAgent.value!
    if (pluginDetail) {
      try {
        const plugins: IPlugin[] = JSON.parse(pluginDetail)
        return plugins.filter((plugin) => {
          const manifest = JSON.parse(plugin.content)
          return manifest.identifier === identifier
        })
      } catch (error) {}
    }
    return []
  }
  /**
   * @description 更新内容
   */
  const updateContent = async () => {
    isAbort.value = true
    clearAllTimers()
    file.value = []
    scrollToBottom()
    updateTopic()
  }
  const timers = ref<{ promise: Promise<void>; cancel: () => void }[]>([])

  function addTimer(callback: () => void, delay: number) {
    let timeoutId: number

    const promise = new Promise<void>((resolve) => {
      timeoutId = window.setTimeout(() => {
        callback()
        resolve()
      }, delay)
    })

    const cancel = () => clearTimeout(timeoutId)

    timers.value.push({ promise, cancel })
  }

  // 创建一个函数来清除所有定时器
  function clearAllTimers() {
    timers.value.forEach(({ cancel }) => cancel())
    timers.value = []
  }
  return { sendMessage, stopStream, name: 'plugin-chat' }
}
async function parseImageMessage(message: IChatMessage): Promise<ImageMessage[] | undefined> {
  const data: ImageMessage[] = []
  for await (const item of message!.attachments!) {
    if (ImageMimeTypes.includes(item.type)) {
      try {
        let base64Str = item.file
        if (isFileOrBlobInstance(item.file)) {
          base64Str = await fileToBase64(item.file)
        }
        data.push({
          image_url: {
            detail: 'auto',
            url: base64Str,
          },
          type: 'image_url',
        })
      } catch (error) {}
    }
    data.unshift({ text: message.content, type: 'text' })
    return data
  }
}
