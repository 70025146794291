import { ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { storeToRefs } from 'pinia'
import { presetTools } from './tools'
import { userStore } from '@/store'
import { ChatHookParams } from './types'
import { ChatService, Tool } from '@/api/chatService'
import { sleep, isAbortError } from '@/utils'
import { createOpenAIStreamProcessor } from '@/utils/llmStreamProcessor'
import { handleToolCallsResponse, parseAvailableTools, parseHistoryMessages } from './helper'

const DEVELOP_CHAT_CONFIG = {
  CHAT_FLOW_ID: import.meta.env.VITE_CHAT_FLOW_ID,
  MODEL: 'gpt-40',
  TEMPERATURE: 0.6,
  TOP_P: 1,
  TYPING_DELAY: { min: 10, max: 20 },
} as const

export function useDevelopChat({ scrollToBottom, currentChatMessages }: any) {
  const isLoading = ref(false)
  let currentController: AbortController | null = null
  const chatService = new ChatService()
  const { userInfo } = storeToRefs(userStore())
  const manifests = ref(presetTools)
  const currentMessage = ref<IChatMessage | undefined>(undefined)

  const stopStream = () => {
    currentMessage.value.status = 0
    if (!currentMessage.value.content) {
      currentMessage.value.content = '已手动中断'
    }
    currentController?.abort()
    resetState()
  }
  const resetState = () => {
    currentController = null
    isLoading.value = false
  }
  const handleStreamResponse = async (response: Response) => {
    const processor = createOpenAIStreamProcessor({
      onStart: () => {
        currentMessage.value.status = 3
      },
      onToken: async (token: string) => {
        if (!isLoading.value || !currentMessage.value) {
          return
        }
        if (!currentMessage.value.content) {
          currentMessage.value.status = 2
        }
        await appendTokenWithDelay(token)
      },
      onToolCall: (toolCalls) => handleToolCall(toolCalls),
      onFinish: () => handleStreamFinish(),
      onError: () => {
        currentMessage.value.content = '解析错误, 请稍后再试'
        currentMessage.value.status = 1
        resetState()
      },
    })

    try {
      const reader = response
        .body!.pipeThrough(new TextDecoderStream())
        .pipeThrough(processor)
        .getReader()
      while (true) {
        const { done } = await reader.read()
        if (done) {
          break
        }
      }
    } catch (error) {
      if (!isAbortError(error)) {
        if (currentMessage.value) {
          currentMessage.value.content = '解析错误, 请稍后再试'
          currentMessage.value.status = 1
        }
      }
      resetState()
    }
  }

  const appendTokenWithDelay = async (token: string) => {
    const chars = token.split('')
    try {
      for (const char of chars) {
        if (!isLoading.value || !currentMessage.value) {
          return
        }
        currentMessage.value.content += char
        await sleep(
          Math.random() *
            (DEVELOP_CHAT_CONFIG.TYPING_DELAY.max - DEVELOP_CHAT_CONFIG.TYPING_DELAY.min) +
            DEVELOP_CHAT_CONFIG.TYPING_DELAY.min,
          currentController!.signal,
        )
        scrollToBottom?.()
      }
    } catch (error) {
      if (isAbortError(error)) {
        resetState()
      }
    }
  }
  /**
   * @description 处理流式响应的工具调用
   */
  const handleToolCall = (toolCalls: any) => {
    if (!currentMessage.value) {
      return
    }
    currentMessage.value.prompt = ''
    currentMessage.value.toolCalls = handleToolCallsResponse(toolCalls)

    for (const tool of toolCalls) {
      if (tool.function?.arguments) {
        currentMessage.value.prompt += tool.function.arguments
        currentMessage.value.toolCalls.arguments += tool.function.arguments
      }
    }
    scrollToBottom?.()
  }
  /**
   * @description 处理流式响应的结束
   */
  const handleStreamFinish = async () => {
    if (!currentMessage.value) {
      return
    }
    if (currentMessage.value.toolCalls) {
      await handleToolCallsFinish()
    } else {
      currentMessage.value.status = 0
    }
    scrollToBottom?.()
    resetState()
  }
  /**
   * @description 处理流式响应的工具调用结束
   */
  const handleToolCallsFinish = async () => {
    if (!currentMessage.value.toolCalls) {
      return
    }
    // 根据identifier和apiName在manifests中找到对应的插件，判断api集合是否有url
    const manifest = manifests.value.find(
      (plugin) => plugin.identifier === currentMessage.value.toolCalls?.identifier,
    )
    const api = manifest?.api.find((api) => api.name === currentMessage.value.toolCalls?.apiName)
    if (api?.url) {
      try {
        const manifest = manifests.value.find(
          (plugin) => plugin.identifier === currentMessage.value.toolCalls?.identifier,
        )
        const response = await chatService.gateway(
          {
            ...currentMessage.value.toolCalls,
            manifest,
          },
          userInfo.value.token,
        )
        const data = await response.json()
        currentMessage.value.toolResults = data.code ? data.data : data.msg
        currentMessage.value.status = data.code ? 0 : 1
      } catch (error) {
        currentMessage.value.content = '调用失败'
        currentMessage.value.status = 1
      }
    } else {
      currentMessage.value.toolResults = JSON.parse(currentMessage.value.toolCalls?.arguments)
      currentMessage.value.status = 0
    }
  }
  /**
   * @description 创建聊天流请求
   */
  const createChatStreamRequest = async (
    message: string | undefined,
    messages: any[],
    availableTools: Tool[],
    controller: AbortController,
  ) => {
    const response = await chatService.createChatStream(
      {
        chatFlowId: DEVELOP_CHAT_CONFIG.CHAT_FLOW_ID,
        question: message || '1',
        messages,
        model: DEVELOP_CHAT_CONFIG.MODEL,
        stream: true,
        tools: availableTools,
        temperature: DEVELOP_CHAT_CONFIG.TEMPERATURE,
        top_p: DEVELOP_CHAT_CONFIG.TOP_P,
        frequency_penalty: 0,
        presence_penalty: 0,
      },
      controller.signal,
      userInfo.value.token,
    )

    if (!response.ok) {
      throw new Error(`API request failed: ${response.statusText}`)
    }

    return response
  }
  /**
   * @description 创建新的消息
   */
  const createNewMessage = (): IChatMessage => ({
    id: uuidv4(),
    role: 'assistant',
    content: '',
    prompt: '',
    date: new Date().toString(),
    status: 3,
  })
  const sendMessage = async (payload: ChatHookParams) => {
    const { index = undefined, message } = payload
    currentController = new AbortController()
    const availableTools: Tool[] = parseAvailableTools(manifests.value)
    const newMessage = createNewMessage()
    currentChatMessages.value.push(newMessage)
    currentMessage.value = currentChatMessages.value[currentChatMessages.value.length - 1]
    const messages = await parseHistoryMessages(currentChatMessages.value, index)

    try {
      isLoading.value = true
      const response = await createChatStreamRequest(
        message,
        messages,
        availableTools,
        currentController,
      )
      await handleStreamResponse(response)
    } catch (error) {
      currentMessage.value.content = '终止回答或服务错误, 请重试'
      currentMessage.value.status = 1
      // resetState(targetHistoryId)
    } finally {
      isLoading.value = false
    }
  }

  const clearMessages = () => {
    stopStream()
  }

  return {
    isLoading,
    sendMessage,
    stopStream,
    clearMessages,
  }
}
