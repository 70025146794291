<script setup lang="ts">
import { cn } from '@/utils'
import { useClipboard } from '@vueuse/core'
import { Clipboard, ClipboardCheck, ChevronDown, ChevronUp, Brain } from 'lucide-vue-next'
import { getHighlighterCore } from 'shiki/core'
import getWasm from 'shiki/wasm'
import light from 'shiki/themes/min-light.mjs'
import dark from 'shiki/themes/min-dark.mjs'
defineOptions({ name: 'yamlx' })
const props = withDefaults(defineProps<{ json: string | undefined; isCollapsed?: boolean }>(), {
  isCollapsed: true,
  json: '{}',
})
const html = ref('')

const highlighter = await getHighlighterCore({
  themes: [light, dark],
  langs: [import('shiki/langs/json.mjs')],
  loadWasm: getWasm,
})

try {
  const json = JSON.stringify(JSON.parse(props.json), null, 2)
  html.value = highlighter.codeToHtml(json || '{}', {
    lang: 'json',
    themes: {
      light: 'min-light',
      dark: 'min-dark',
    },
  })
} catch (err) {
  html.value = props.json
}

const { copy, copied, isSupported } = useClipboard({
  source: props.json,
  legacy: true,
})
const CopyRef = computed(() => (copied.value ? ClipboardCheck : Clipboard))

/**
 * @description 文本复制
 */
const coptHandle = async () => {
  await copy(props.json)
  window.$message?.success('复制成功')
}

const show = ref(!props.isCollapsed)
const Icons = computed(() => (show.value ? ChevronUp : ChevronDown))
</script>

<template>
  <div
    v-if="isCollapsed"
    class="flex items-center gap-2 cursor-pointer p-2 pl-0 rounded-lg hover:text-primary w-32 mt-[-10px] select-none text-sm"
    @click="show = !show"
  >
    <p class="flex items-center gap-1"><Brain class="h-4 w-4" />执行结果</p>

    <Icons class="h-4 w-4" />
  </div>
  <div
    v-show="show"
    :class="
      cn('flex justify-between gap-2 items-start  p-2 rounded-lg mt-1', isCollapsed && 'bg-muted')
    "
  >
    <div class="json_yaml1 flex-1 overflow-x-auto" style="height: 200px" v-html="html"></div>
    <CopyRef
      v-if="isSupported"
      title="复制"
      class="h-5 w-5 cursor-pointer opacity-0 group-hover:opacity-100 hover:stroke-primary"
      @click="coptHandle"
    />
  </div>
</template>
<style lang="scss">
.json_yaml1 {
  span {
    white-space: normal;
  }
}
</style>
