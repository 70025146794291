<template>
  <div class="w-full h-full">
    <div class="h-[60%]">
      <Flow></Flow>
    </div>
    <div class="h-[40%]">
      <!-- {{ taskId }} -->
      <div class="terminal-container h-full flex flex-col">
        <div
          ref="terminalElement"
          class="terminal-content overflow-auto"
          style="height: calc(100% - 4em)"
        >
          <Terminal v-model="logArr" @clear="clearLogArr"></Terminal>
          <!-- <RenderMarkdown :source="content"></RenderMarkdown> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Terminal, { LogItem } from '@/featrues/TaskEditor/Terminal.vue'
import { SquareTerminal, Trash2, X, ChevronUp, Plus } from 'lucide-vue-next'
import Flow from './widgets/flow.vue'
// import { RenderMarkdown } from '@/components/RenderMarkdown'
import { useWebSocket } from '@vueuse/core'
import { userStore } from '@/store'
const dashboardRef = inject<any>('dashboard')
const { flowData } = dashboardRef
const terminalElement = ref<HTMLElement | null>(null)
const { PROD } = import.meta.env

let socket: any = undefined
const logArr = ref<any[]>([])
const user = userStore()
// 图数据

function connectToSocket() {
  if (!user.userInfo && !user.userInfo.userId) {
    return
  }
  // const wsurl = PROD
  //   ? `${window.VITE_HTTP_PROXY.replace(/^http:\/\//i, 'ws://')}/websocket/process/${user.userInfo.userId}`
  //   : 'ws://10.172.246.101:9100/llm/websocket/process/98'
  const wsurl = PROD
    ? `${window.VITE_HTTP_PROXY.replace(/^http:\/\//i, 'ws://')}/websocket/process/${user.userInfo.userId}`
    : `ws://10.172.246.101:9100/llm/websocket/process/${user.userInfo.userId}`
  socket = useWebSocket(wsurl, {
    onConnected(ws) {
      flowData.value = []
      console.log('连接成功')
    },
    onMessage(ws, event) {
      if (event.data !== 'pong') {
        const data = JSON.parse(event.data)
        // --------------------------------------------------------
        logArr.value.push(data)

        // 图数据flowData
        const fi = flowData.value.findIndex((item) => {
          return item.id === data?.step?.toString()
        })
        if (fi > -1) {
          flowData.value[fi].status = data.status
        } else {
          const flow = {
            status: data.status,
            id: data?.step?.toString(),
          }
          flowData.value.push(flow)
        }
        // console.log(flowData.value, '1231321')
      }
    },
    onError(ws, event) {
      console.log('连接失败')
    },
    onDisconnected(ws, event) {
      console.log('断开连接')
    },
    heartbeat: {
      message: `ping`,
      interval: 10000,
      pongTimeout: 10000,
    },
  })
}

connectToSocket()

onUnmounted(() => {
  socket.close()
})

const clearLogArr = () => {
  logArr.value.splice(0)
}

const content = ref('123123213')
</script>
