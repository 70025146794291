import { LobeChatPluginApi, PluginRequestPayload } from '@rzx/chat-plugin-sdk'
const baseUrl = import.meta.env.DEV ? '/api' : ''
export class ChatService {
  async createChatStream(
    payload: ChatQuery,
    signal: AbortSignal,
    Chattoken: string,
  ): Promise<Response> {
    const formData = this.objectToFormData(payload)
    const response = await fetch(`${baseUrl}/llm/skillCenter/plugin/chat/openai/formdata`, {
      method: 'POST',
      signal,
      headers: {
        Chattoken,
        'api-key': '61c36ab3c518418b916a6ffc2190d170',
        // 'Content-Type': 'multipart/form-data',
      },
      body: formData,
    })

    if (!response.ok) {
      throw new Error(`API request failed: ${response.statusText}`)
    }

    return response
  }
  async gateway(params: PluginRequestPayload, Chattoken: string): Promise<Response> {
    const response = await fetch(`${baseUrl}/llm/skillCenter/plugin/gateway`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Lobe-Plugin-Settings': `{"BING_API_KEY":"${import.meta.env.VITE_BING_API_KEY}","count":"8"}`,
        Chattoken,
      },
      body: JSON.stringify(params),
    })

    if (!response.ok) {
      throw new Error(`Gateway API request failed: ${response.statusText}`)
    }

    return response
  }

  /**
   * 将对象转换为 FormData，对象类型直接 JSON 序列化
   * @param obj 要转换的对象
   * @returns FormData
   */
  objectToFormData = (obj: Record<string, any>): FormData => {
    const formData = new FormData()

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key]

        if (value === null || value === undefined) {
          continue
        } else if (value instanceof File || value instanceof Blob) {
          formData.append(key, value)
        } else if (typeof value === 'object') {
          // 对象类型直接 JSON 序列化
          formData.append(key, JSON.stringify(value))
        } else {
          formData.append(key, String(value))
        }
      }
    }

    return formData
  }
}
export interface ChatQuery {
  /**
   * @description 问题，主要用以校验铭感词逻辑
   */
  question?: string
  frequency_penalty: 0
  messages: MessageType[]
  files?: File[]
  model: string
  presence_penalty: 0
  stream: boolean
  temperature: 0.6
  tools: Tool[]
  top_p: 1
  [key: string]: any
}
export interface Tool {
  function: Partial<LobeChatPluginApi>
  type: string
}

export interface ImageSchema {
  placeholder: string
  url: string
  error?: string
}

export interface ImageMessage {
  text?: string
  type?: string
  image_url?: {
    detail: string
    url: string
  }
}
export interface MessageType {
  content: string | ImageMessage[] | undefined
  name?: string
  tool_call_id?: any
  role: IChatRole
}
