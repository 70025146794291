import { defineStore, storeToRefs } from 'pinia'
import { getDefaultChatFlow } from '@/api'
import { useChatDB, userStore } from '@/store'
import { v4 as uuidv4 } from 'uuid'
import { defaultAgent } from '@/contants/defaultAnget'
import {
  getResourceTree as getResourceTreeApi,
  saveOrUpdateTree as saveOrUpdateTreeApi,
  deleteTree as deleteTreeApi,
} from '@/api/resouce'

export const developStore = defineStore(
  'develop-store',
  () => {
    interface TreeItem {
      id: string
      name: string
      type: 'file' | 'folder'
      children?: TreeItem[]
    }

    const zyListLoading = ref(false)
    // 资源列表
    const zyList = ref<TreeItem[]>([
      {
        id: '根目录',
        name: '根目录',
        type: 'folder',
        children: [],
      },
    ])

    function transformChildrenToNull(data) {
      // 如果 data 是数组，遍历每一项
      if (Array.isArray(data)) {
        return data.map(transformChildrenToNull)
      }

      // 如果 data 是对象
      if (typeof data === 'object' && data !== null) {
        // 如果 children 是空数组，将其设为 null
        if (Array.isArray(data.children) && data.children.length === 0) {
          data.children = null
        }
        // 否则递归处理 children
        else if (Array.isArray(data.children)) {
          data.children = transformChildrenToNull(data.children)
        }
      }

      return data
    }
    // 获取资源列表
    const getZyList = async () => {
      zyListLoading.value = true
      const res = await getResourceTreeApi()
      if (res.code === 1) {
        zyList.value[0].children = transformChildrenToNull(res.data)
      } else {
        window.$message.error(res.msg || '未知错误')
      }
      zyListLoading.value = false
    }
    // 当前选中工具
    const curtZy: any = ref({})

    // 新建文件夹
    const addFolder = async (parentId, name) => {
      zyListLoading.value = true
      const res = await saveOrUpdateTreeApi({
        name: name,
        parentId: parentId === '根目录' ? '' : parentId,
        type: 'folder',
      })
      if (res.code === 1) {
        getZyList()
      } else {
        window.$message.error(res.msg || '未知错误')
      }
      zyListLoading.value = false
    }

    // 新建文件
    const addFile = async (parentId, name) => {
      zyListLoading.value = true
      const res = await saveOrUpdateTreeApi({
        name: name,
        parentId: parentId === '根目录' ? '' : parentId,
        type: 'file',
      })
      if (res.code === 1) {
        getZyList()
      } else {
        window.$message.error(res.msg || '未知错误')
      }
      zyListLoading.value = false
    }

    // 修改节点名称
    const renameFolderOrFile = async (item, newName) => {
      zyListLoading.value = true
      const res = await saveOrUpdateTreeApi({
        id: item.id,
        name: newName,
        parentId: item.parentId,
        processId: item.processId,
        type: item.type,
      })
      if (res.code === 1) {
        getZyList()
      } else {
        window.$message.error(res.msg || '未知错误')
      }
      zyListLoading.value = false
    }

    // 删除节点
    const deleteFolderOrFile = async (item) => {
      zyListLoading.value = true
      const res = await deleteTreeApi({
        id: item.id,
      })
      if (res.code === 1) {
        getZyList()
      } else {
        window.$message.error(res.msg || '未知错误')
      }
      zyListLoading.value = false
    }

    const curtTools: any = ref([])

    const nodeInfo = ref({})
    const identifier = ref('')

    return {
      zyList,
      getZyList,
      curtZy,
      addFolder,
      addFile,
      zyListLoading,
      renameFolderOrFile,
      deleteFolderOrFile,
      curtTools,
      nodeInfo,
      identifier,
    }
  },
  {
    persist: {
      key: 'developStore',
      storage: localStorage,
      paths: ['initalAnget'],
    },
  },
)
