import { PluginRequestPayload, PluginType } from '@rzx/chat-plugin-sdk'
import { ToolCall } from './types'
import { Md5 } from 'ts-md5'
const getPluginTitle = (meta?: MetaData) => meta?.title
const getPluginDesc = (meta?: MetaData) => meta?.description

const getPluginTags = (meta?: MetaData) => meta?.tags
const getPluginAvatar = (meta?: MetaData) => meta?.avatar || '🧩'

const getPluginNameByIdentifier = (identifier: string) => {
  const [pluginName, pluginFunctionName, pluginType] = identifier.split(PLUGIN_SCHEMA_SEPARATOR)
  return [pluginName, pluginFunctionName, pluginType] as [string, string, PluginType]
}

const isSettingSchemaNonEmpty = (schema?: PluginSchema) =>
  schema?.properties && Object.keys(schema.properties).length > 0

/**
 *
 * @description 获取多媒体消息
 * @param data
 */

const getMediaMessages = (data: any): MediaMsg[] => {
  const mediaMessage: any[] = []
  const types: ConversationType[] = ['pie_chart', 'bar_chart', 'line_chart', 'table', 'sql']
  types.forEach((type) => {
    if (data[type]) {
      mediaMessage.push({ type, data: data[type] })
    }
  })
  return mediaMessage
}
export {
  getPluginAvatar,
  getPluginDesc,
  getPluginTags,
  getPluginTitle,
  isSettingSchemaNonEmpty,
  getPluginNameByIdentifier,
  getMediaMessages,
}
/**
 *@description 数据库会话逻辑
 */
export type MediaMsg = {
  type: ConversationType
  data: any
}

export const genMd5 = (name: string) => Md5.hashStr(name).toString()
export const PLUGIN_SCHEMA_SEPARATOR = '__'
export const PLUGIN_SCHEMA_API_MD5_PREFIX = 'MD5HASH_'

/**
 * @description 处理工具调用响应
 * 如果apiName是md5哈希值, 则从插件清单中找到对应的api
 * 否则, 直接使用apiName
 */
export const handleToolCallsResponse = (toolCalls: ToolCall[]): PluginRequestPayload => {
  let payload: PluginRequestPayload | undefined
  const firstCall = toolCalls[0]
  if (firstCall?.id && firstCall?.function?.name) {
    const [identifier, apiName, pluginType] = getPluginNameByIdentifier(firstCall.function.name)
    payload = {
      id: firstCall.id,
      apiName,
      arguments: '', // 将在后续流中累积
      identifier,
      type: pluginType || 'markdown',
    }
    // 如果apiName是md5哈希值, 则从插件清单中找到对应的api
    if (apiName?.startsWith(PLUGIN_SCHEMA_API_MD5_PREFIX)) {
      const md5 = apiName.replace(PLUGIN_SCHEMA_API_MD5_PREFIX, '')
      const tool = useToolStore().getToolByIdentifier(identifier)
      const api = tool?.api.find((api) => genMd5(api.name) === md5)
      if (api) {
        payload.apiName = api.name
      }
    }
  }
  return payload
}
